import React from 'react'
import { connect } from "react-redux"

/**
  * @desc About Modal
*/

const mapStateToProps = state => {
  return {

  }
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

const ModalSettings = () => {

  // Display component
  return (
    <div key="modalSettings">Settings</div>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(ModalSettings)
