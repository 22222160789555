// Version and release notes
const version = [
  {
    version: `1.2`,
    date: `21.06.20`,
    code: `Permadeath`,
    reset: false,
    note: `A final run, the Abyss of Permadeath, is now available after the last boss is defeated (thanks to GrandMaster Bab for the idea). The run is extra hard, and death is final.`,
    changes: [
      `New last run in super hard mode`,
      `Better endgame screens (they still suck)`,
      `Various nerfing`,
      `Various bug fixes`,
      `Hall of fame scores reset`
    ],
  },
  {
    version: `1.1`,
    date: `18.06.20`,
    code: `Git gud`,
    reset: `run`,
    note: `Beta testers complain the game is too hard. Filthy casuals. Game difficulty lowered and testing a less punitive elite system.`,
    changes: [
      `Global monster nerfing`,
      `Adding 5 more levels (now 30)`,
      `Boss every 5 levels replaces the random/frustrating elites`,
      `Game creation refacto : all levels computed at start`,
      `Better level roadmap`,
      `New ennemies`,
      `Release note view + run reset system`,
    ],
  },
  {
    version: `1.0`,
    date: `13.06.20`,
    code: `Are we there yet?`,
    reset: false,
    note: `First "almost done" release, w00t.`,
    changes: [],
  }
]

export {
  version
}