
/**
  * @desc Opening shop
*/

const monstersDemo = (data) => {
  // Set game state
  data.game.state = `monstersDemo`

  return data
}

export { monstersDemo }
