// Main game settings
const gameSettings = {
  maxLevel: 30, // Highest level of the game
  zones: 6, // Number of sections in levels (should be 5)
  combatSpeed: 3000,
  widgetDelay: 600,
  itemStateDelay: 2000,
  monsterCharPointsRange: [8, 110], // Range of monsters points, from level 1 to last level
  monsterCharPointsRange_loop: [120, 500], // Range of monsters points, from level 1 to last level
  eliteCharPointsRange: [30, 50], // elite % spec bonus
  beastHealthBoostRange: [30, 60], // non humanoid HP boost
  manualCharBoostRange: [30, 60], // manual char boost specified in monster settings
  postScoreUrl: "https://mhd20.com/postscore/",
  getScoreUrl: "https://mhd20.com/getscore/",
  maxEnergyMultiplyer: {
    STR: 10,
    MAG: 10,
    CON: 10
  },
  skillsRecharge: {
    heal: 3,
    stun: 3,
    itembreak: 3,
    reflect: 4,
    psyblast: 3,
    curse: 3,
  },
  icons: {
    battleStart: [`book`, 1],
    block: [`skill`, 6],
    focus: [`skill`, 2],
    specialattack: [`skill`, 3],
    specialcast: [`skill`, 0],
    stun: [`skill`, 8],
    itembreak: [`skill`, 5],
    psyblast: [`skill`, 4],
    curse: [`skill`, 1],
    attackfumble: [`skill`, 9],
    castfumble: [`skill`, 9],
    critical: [`skill`, 10],
    fumble: [`skill`, 11],
    skip: [`skill`, 12],
    heal: [`skill`, 13],
    reflect: [`skill`, 14],
    defending: [`skill`, 15],
    boost: [`skill`, 16],
    reflectfail: [`skill`, 17],
  }
}

// Initial game state
const initialState = {
  game: {
    state: `welcome`,
    settings: {
      combatSpeed: gameSettings.combatSpeed
    }
  }
}

// Default char settings
const defaultChars = {
  warrior: {
    STR: 8,
    DEX: 4,
    CON: 6,
    MAG: 2,
    LCK: 1,
    fumble: 1,
    startItem: `STR`
  },
  mage: {
    STR: 2,
    DEX: 5,
    CON: 4,
    MAG: 9,
    LCK: 1,
    fumble: 1,
    startItem: `MAG`
  },
  thief: {
    STR: 5,
    DEX: 6,
    CON: 4,
    MAG: 5,
    LCK: 2,
    fumble: 2,
    startItem: `DEX`
  }
}

// Default consumables
const defaultInstants = {
  warrior: [`quickheal`, `damage`, `restore`, `sharpenphysical`],
  mage: [`quickheal`, `damage`, `temporaryupgrade`, `sharpenmagical`],
  thief: [`quickheal`, `restore`, `temporaryluckupgrade`, `permanentupgrade`]
}

export {
  initialState,
  gameSettings,
  defaultChars,
  defaultInstants
}