
/**
  * @desc Starting a new battle
*/

const logsToPlayerTurn = (data) => {
  // Set log to playerTurn
  // data.log = { type: `playerTurn` }
  data.log = {}

  return data
}

export { logsToPlayerTurn }
